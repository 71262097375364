import React from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import Header from "./components/Header/index";
import RouterOutlet from "./router-outlet";
import "./custom-theme.scss";
import "./App.scss";

function App(props) {
  // const location = useLocation();
  // console.log(location.pathname);
  return (
    <BrowserRouter>
      {/* <Header {...props} /> */}
      <RouterOutlet />
    </BrowserRouter>
  );
}

export default App;
